<template>
  <v-container class="pa-4">
    <v-row>
      <v-col v-for="(item, index) in items" :key="index" class="mb-4" cols="12">
        <v-card color="#303030" dark>
          <v-row>
            <v-col cols="12" md="3">
              <v-card-title
                style="background-color: #dfc35b; text-decoration: none"
                class="text-center lighten-2 rounded-r-lg text-uppercase text-h6 font-weight-bold mr-2 text-justify text-wrap"
              >
                {{ item.led_text }}
              </v-card-title>

              <v-img
                :src="item.image"
                height="250"
                contain
                class="mx-2 my-4"
                :lazy-src="item.image"
              ></v-img>
            </v-col>

            <v-col cols="12" md="9" class="pa-6">
              <v-card-title
                class="card-title"
                style="font-size: 30px; font-weight: bold; color: #fff"
              >
                {{ item.title }}
              </v-card-title>
              <v-col class="d-flex inline-flex py-0">
                <v-avatar>
                  <v-img contain :src="item.instructor.photo"></v-img>
                </v-avatar>
                <v-col class="d-flex flex-column py-0">
                  <div>
                    {{ item.instructor.name }}
                  </div>

                  <div>
                    {{ item.instructor.designation }}
                  </div>
                </v-col>
              </v-col>
              <v-divider class="my-4"></v-divider>
              <v-card-text
                class="text-h6 font-weight-light py-0 my-3 text-justify"
              >
                {{ item.description }}
              </v-card-text>
              <!-- <v-btn
                color="orange darken-3 mt-auto"
                link
                class="text-uppercase font-weight-bold"
                :to="'/workshop/' + item.slug"
              >
                View Schedules and Details
              </v-btn> -->
              <v-btn
                color="orange darken-3 mt-auto"
                link
                class="text-capitalize font-weight-bold"
                :to="'/workshop/' + item.slug"
                style="font-size: 17px"
              >
                View Schedules and Details
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
  
  
<script>
export default {
  data() {
    return {
      dialogCompleteEnrollment: false,
      selectedBatchRoom: [],
      headers: [
        { text: "Days", value: "days" },
        { text: "From-To", value: "from_to_date" },
        { text: "Time", value: "from_to_time" },
      ],
      //   items: [
      //     {
      //       id: 1,
      //       name: "March 13 th",
      //       age: "SAT & Sun (6 Weeks)",
      //       gender: "01:00 PM to 3:00 PM (BDT)",
      //     },
      //     {
      //       id: 2,
      //       name: "March 14 th",
      //       age: "SAT & Sun (6 Weeks)",
      //       gender: "01:00 PM to 3:00 PM (BDT)",
      //     },
      //     {
      //       id: 3,
      //       name: "March 13 th",
      //       age: "SAT & Sun (6 Weeks)",
      //       gender: "01:00 PM to 3:00 PM (BDT)",
      //     },
      //   ],
      items: [],
      pagination: {},
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      const params = {}
      if(window.location.host==='careercoach.meetingme.live'){
        console.log('careercoach.meetingme.live')
        params['offered_by']='careercoach'
      }
      else{
        params['not_offered_by']='careercoach'
      }
      return this.$axios
        .get("/workshops", { params })
        .then(
          (response) => {
            const { results, ...pagination } = response.data;
            this.items = results;
            this.pagination = pagination;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    showEnrollmentDialog() {
      if (this.selectedBatchRoom == "") {
        alert("Please select a batch room");
        return;
      }
      this.dialogCompleteEnrollment = true;
    },
  },
};
</script>
<style scoped>
td {
  white-space: nowrap;
}
.v-data-table-header__icon.notranslate {
  display: none;
}
.card-title:hover {
  color: #b2beb5;
  cursor: pointer;
}
</style>